
<template>
  <div>
      <!-- 普通成员 -->
         <div  class="applymain">
          <div class="main">
            <h3>申请管理员</h3>
             <el-form ref="form" :model="resultApp" label-width="110px">
  <el-form-item label="证件类型">
    <div class="five">
       <el-radio v-model="resultApp.certtype" label="0">三证合一</el-radio>
  <el-radio v-model="resultApp.certtype" label="1">五证合一</el-radio>
  </div>
  </el-form-item>
    <el-form-item label="营业执照">
        <el-upload
            class="avatar-uploader"
            :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="cohandleAvatarSuccess"
            :headers="httpheader"
             :before-upload="beforeAvatarUpload"
              v-loading="loading"
                :on-progress = "oprogress"
          >
           <img
              
         v-if="resultApp.colicense"
              :src="resultApp.colicense"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
  </el-form-item>
    <el-form-item label="企业认证授权书">
         <el-upload
            class="avatar-uploader"
         :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="ahandleAvatarSuccess"
             :before-upload="beforeAvatarUpload"
            :headers="httpheader"
          >
            <img
              
            v-if="resultApp.authorization"
              :src="resultApp.authorization"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
  </el-form-item>
  </el-form>
  
          </div>
          <div class="bottom">
         <el-button type="primary" @click="addAdminRecord">申请管理员</el-button>
  <el-button type="success" @click="seceded">退出企业</el-button>
  </div>
     </div>
  </div>
</template>
<script>
import basecfg from "../../../utils/applocationCfg"
import { Message,MessageBox } from "element-ui";
import companyApi from "../../../api/companyApi";
import administratorApi from "../../../api/administrator"
export default {
    data(){
        return{
           baseApi:basecfg.getBaseApi(),
               loading:false,
              httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },  
               // 申请表单
       resultApp:{
          colicense:"",
          authorization:"",
          certtype:"0",
       },
        }
    },
    methods:{
       // 限制大小
         beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
         Message.error('上传大小不能超过 4MB!');
        }
        return isLt2M;
      
    },
       // 上传时
    oprogress(){
      this.loading = true
    },
    // 营业执照
    cohandleAvatarSuccess(res, file) {
      this.resultApp.colicense = URL.createObjectURL(file.raw);
   
       this.loading = false
     
    },
    // 企业认证授权书
    ahandleAvatarSuccess(res, file) {
      
      this.resultApp.authorization = URL.createObjectURL(file.raw);
    
       this.loading = false
   
    },
    //  申请管理员
addAdminRecord(){
  companyApi.addAdminRecord({certtype:this.resultApp.certtype,colicense:this.resultApp.colicense,authorization:this.resultApp.authorization},res=>{
     
      if(res.data.code==200){
            Message({
          message: '已成功申请，请等待审核',
          type: 'success'
        });
 
      }
      else{
         Message.error(res.data.message)
      }
      })
},
      //退出企业
   seceded(){
  MessageBox.confirm('确定要退出企业吗', '退出所属企业', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         
          administratorApi.outCompany("",res=>{
            
              if(res.data.code!==200){
                   Message.error(res.data.message);
              }
              if(res.data.code==200){
                 Message.success("退出企业成功") 
               this.$router.push("/home/personalCenter") 
               setTimeout(()=>{
 this.$router.go(0)
               },500)
               
          };     
                     
          })
        
        }).catch(() => {
         
          Message({
            type: 'info',
            message: '已取消退出企业'
          });          
        });

      }
  }
}
</script>
<style scoped lang="scss">
.avatar{
  width: 30px;
  height: 30px;
}
     .applymain{
  // border: 1px solid red;
  width: 800px;
  height: 500px;
  .main{
    width: 60%;
    margin: 0 auto;
    height: 400px;
    .el-form-item{
       border-bottom: 1px solid #ccc;
       padding-bottom: 5px;
       
      //  float: left;
    }
 ::v-deep .el-form-item__label {
    text-align: left !important;
    font-size: 14px;
   
  }
    ::v-deep .el-upload{
     border: 0px !important;
     width: 690px;
    //  float: right;
    //  border: 1px solid red;
  }
  h3{
    // border: 1px solid red;
    text-align: center;
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 500;
    color: red;
  }
  }
  .bottom{
    // border: 1px solid red;
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .el-button{
      margin: 0;
      width: 120px;
    }
      .el-button:nth-child(2){
      margin: 0;
      width: 120px;
      background-color: rgb(233,79,85);
      border: 0;
    }
  }
}
</style>