
<template>
  <div>
      <!-- 管理员 -->
         <div class="admain">
      <div class="top">
         
          <div  class="item">
          <div
          v-for="(tabBar, index) in data" :key="index"
            class="a"
            :class="{ active: tabIndex == tabBar.id }"
            @click="toggleTab(tabBar.id)"
          >
            {{ tabBar.name }}
          </div>
      </div>
      </div>
      <div class="centercode" v-if="type == 1">
        <div class="centerall">
          <div class="centername" v-for="(item,index) in CompanyStaffList" :key="index">
            <div class="left">
               <viewer>
              <img :src="item.image" alt="" />
              </viewer>
              <div class="message">
                <p class="name">{{item.name?item.name:"未设置昵称"}}</p>
                <p class="phone">{{item.phone}}</p>
              </div>
            </div>
            <div class="right" v-if="!item.admin">
              <el-button type="primary" @click="open(item.uuid)">删除</el-button>
              <el-button type="danger" @click="administer(item.uuid) ">升级为管理员</el-button>
            </div>
          </div>
          
          
        </div>
       <div class="out">
          <el-button type="danger" @click="seceded">退出所属企业</el-button>
        </div>
      </div>

      <div class="centeruncode" v-if="type == 2">
        <div class="centerall">
          <div class="phone">
            <el-row :gutter="20">
              <el-col :span="12"
                ><div class="grid-content bg-purple">
                  <el-input
                   v-model="producecod"
                    placeholder="请输入被邀请人手机号"
                  ></el-input></div
              ></el-col>
              <el-col :span="11"
                ><div class="grid-content bg-purple">
                   <el-button type="danger" class="dsd" @click="producecode">生成邀请码</el-button>
                  </div
              ></el-col>
            </el-row>
          </div>
            <div class="code">
            <el-row :gutter="20">
              <el-col :span="12"
                ><div class="grid-content bg-purple">
                  <el-input
                    placeholder="FGEUSNFEIDSN"
                    v-model="invitationcode"
                    disabled
                  ></el-input></div
              ></el-col>
              <el-col :span="11"
                ><div class="grid-content bg-purple">
                      <el-button type="danger" class="dsd"  v-clipboard:copy="invitationcode"
        v-clipboard:success="onCopy"
        @click="Copy">复制</el-button>
                  </div
              ></el-col>
            </el-row>
          </div>
       </div>
      
       </div>

         <div class="centeruncode" v-if="type == 3">
            <div class="topboot">
      <span :class="{ active: isActive }" @click="contract">电子签约</span>
      <span :class="{ active: Active }" @click="online">线上支付</span>
    </div>
        <div class="centerall" v-if="contractt">
          <div class="centerallimg">
              <div class="aiqian">
           <span>*</span>开通爱签电子合同
          </div>
          <div>
           <i class="el-icon-circle-check" v-if="sureqian" ></i>
           <i class="el-icon-circle-close" v-else></i>
          </div>
          </div>
          <div class="grid-content bg-purple bottom">
                   <el-button type="danger" class="sure" v-show="!sureqian" @click="sureaiqiana">确认开通</el-button>
                      <el-button v-show="sureqian" type="primary" class="sure">已开通</el-button>
                  </div
              >
       </div>
         <div class="nocenterall" v-else> 
           <div v-if="this.signingprocess==-1">
               <Onlinepaymentone :signingprocess="signingprocess"  @editsiging="editsiging" @childproposer="childproposer"></Onlinepaymentone>
           </div>
        <div v-if="this.signingprocess ==0" >
           <Onlinepaymenttwo :signingprocess="signingprocess" @editsiging="editsiging" :proposer="proposer"></Onlinepaymenttwo>
       </div>
       <div  v-if="this.signingprocess ==1||this.signingprocess ==2" @editsiging="editsiging"   class="imgsubmite"> 
           <Onlinepaymentthree :signingprocess="signingprocess"></Onlinepaymentthree>
       </div>
        
         </div>
       </div>
      </div>
  </div>
</template>
<script>
import { Message,MessageBox} from "element-ui";
import administratorApi from "../../../api/administrator"
import  Onlinepaymentone from './onlineone.vue'
import  Onlinepaymenttwo from './onlinetwo.vue'
import  Onlinepaymentthree from './onlinethree.vue'
export default {
    data(){
        return{
          // 是否法人填写
          proposer:0,
          // 是否开通爱签
          sureqian:false,
            causeDialog: false,
          labelPosition:'left',
        data: [
        { name: "成员管理", id: "0" },
        { name: "邀请新成员", id: "1" },
        { name: "功能管理", id: "2" },
      ],
      signingprocess:0,
       tabIndex: 0,
       contractt:true,
       isActive: true,
      Active: false,
      type: 1,
    //   员工列表
      CompanyStaffList:[],
    // 邀请码
    producecod:"",
    // 复制码
    invitationcode:"",
   
   
        }
    },
    components:{
   Onlinepaymentone,
   Onlinepaymenttwo,
   Onlinepaymentthree
    },
    created(){
          this.getCompanyStaffList() 
          if(this.producecod.length<=0){
     this.invitationcode=""
          }
      
    },
    methods:{
  
      editsiging(params){
         this.signingprocess = params
      },
childproposer(params){
    this.proposer = params
},
      // 开通签约功能
sureaiqiana(){
    administratorApi.openSignContract("",res=>{
       
       if(res.data.code==324){
             Message.error('当前状态禁止该操作，开通失败');
       }
       else if(res.data.code==200){
          Message.success('开通成功');
            this.getSignContract()
       }
       else{
          Message.error('开通失败');
       }
    })
},
          // 电子签约
    contract() {
      this.contractt = true
      this.Active = false;
      (this.isActive = true)
    },
    //线上支付
    online() { 
       this.contractt = false
         this.Active = true;
         (this.isActive = false)
     // 获取当前用户线上支付状态
     administratorApi.getPaymentconfigState("",res=>{
   
         this.signingprocess = res.data.result   
       
     })
       
    },
        // 获取员工列表
           getCompanyStaffList(){
                  administratorApi.getCompanyStaffList("",res=>{
                  
                    if(res.data.code==200){
                    this.CompanyStaffList = res.data.result
                    }
                     
                    
                  })
           },
         //成员管理
    codee() {
      this.type = 1;
      this.Active = false;
      (this.isActive = true)
    },
    //生成邀请码
    uncode() {
      this.type = 2;

      this.Active = true;
      (this.isActive = false)
    },
    // 邀请新成员
      producecode(){
       
      administratorApi.getInviteCode(this.producecod,res=>{
         
          if(res.data.code==200){
            this.invitationcode = res.data.result
        Message({
          message: '发送成功',
          type: 'success'
        }); 
          }
            else{
         Message.error(res.data.message);
         this.producecod=""
          }
      })
      },
      // 查看当前用户是否开通签约功能
      getSignContract(){
   administratorApi.getSignContract("",res=>{
          this.sureqian = res.data.success
        })
      },
      // 流程图table
    toggleTab(index) {
      this.tabIndex = index
       if(this.tabIndex==0){
         this.type=1
      }
      if(this.tabIndex==1){
         this.type=2
      }
       if(this.tabIndex==2){
       this.type=3
     this.getSignContract()
      }
    },
    //删除
      open(staffId) {
        MessageBox.confirm('此操作将永久删除该成员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          administratorApi.delCompanyStaff(staffId,res=>{
            if(res.data.code==200){
             Message({
            type: 'success',
            message: '删除成功!'
          });
            }
            else{
             Message.error(res.data.message);
            }
               this.getCompanyStaffList()
          })
         
        }).catch(() => {
          Message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      //升级管理员
       administer(staffId) {
        MessageBox.confirm('确定升级为管理员吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          administratorApi.setAdmin(staffId,res=>{
           
            if(res.data.code!==200){
                   Message.error(res.data.message);
            }
              if(res.data.code==200){
            Message({
            type: 'success',
            message: '升级成功!'
          });
            }
              this.getCompanyStaffList()
              this.$router.go(0);
          })
        
        }).catch(() => {
          Message({
            type: 'info',
            message: '已取消升级'
          });          
        });
      },
       //退出企业
     seceded(){
 MessageBox.confirm('确定要退出企业吗', '退出所属企业', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         
          administratorApi.outCompany("",res=>{
            
              if(res.data.code!==200){
                   Message.error(res.data.message);
              }
              if(res.data.code==200){
                 Message.error("退出企业成功") 
                  this.$router.push("/home/personalCenter")
                  this.$router.go(0)
          };     
                     
          })
        
        }).catch(() => {
        
          Message({
            type: 'info',
            message: '已取消退出企业'
          });          
        });
      },
       // 复制成功
    onCopy(){
       
           if(this.invitationcode.length>0){
                Message({
        type: 'success',
        message: '复制成功!'
        });
          }
         
    },
    Copy(){
      if(this.invitationcode.length<=0){
              Message.error("没有邀请码，复制失败")
            }
      },


    }
}
</script>
<style scoped lang="scss">
.el-icon-circle-check{
      color: green;
      font-size: 30px;
  }
  .el-icon-circle-close{
      color: red;
      font-size: 30px;
  }
::v-deep .imgsubmite{
  .el-form {
    .el-form-item {
       .el-form-item__content{
    display: flex;
  }
    }
  }
 
}
 .topboot {
   cursor: pointer;
    //  border: 1px solid red;
     width: 50%;
     margin: 0px auto;
     display: flex;
      margin-top: 40px;
     span:nth-child(1){
        border-right: 1px solid #ccc;
      }
    span{
        // border-right: 1px solid #ccc;
       width: 50%;
      font-size: 16px;
      display: inline-block;
      text-align: center;
      padding: 5px;
     
    }
    
  }
     .avatar{
  width: 30px;
  height: 30px;
}
.admain {
  width: 680px;
  // height: 500px;
  margin-top: 20px;
}
.top {
  cursor: pointer;
  margin-left: 0px;
  .item{
    display:flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
    font-size: 18px;
    div{
      border-right: 1px solid rgb(199, 197, 197);
      padding-right: 50px;
      // color: forestgreen;
    }
    div:nth-last-child(1){
      border: 0;
    }
    
  }
}

.top .active {
  color: #e94f55;
}
.nocenterall{
  // border: 1px solid red;
  width: 110%;
  padding-top: 10px;
  margin-bottom: 10px;
}
.centercode {
  width: 100%;
  background: rgb(253, 253, 253);
  margin-top: 40px;
  // border: 1px solid red;

  height: 400px;
  .centerall {
    height: 100%;
    width: 90%;
    margin: 10px auto; 
    // border: 1px solid red;
  .centerallimg{
    display: flex;
  }
    
  }
  
}
 ::v-deep .el-button{
     margin: 0;
     height: 35px;
     line-height: 10px;
   }
.centername {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 10px;
}
.centername .left {
  // border: 1px solid rgb(255, 123, 0);
  display: flex;
  justify-content: space-between;
  img {
    width: 50px;
    height: 50px;
    // border: 1px solid red;
    border-radius: 60px;
  }
  .message {
    margin-left: 10px;
    // border: 1px solid rgb(255, 123, 0);
    height: 30px;
    line-height: 30px;
    .name {
     
      height: 30px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      line-height: 30px;
    }
    .phone {
      width: 121px;
      height: 28px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.56);
      line-height: 28px;
    }
  }

}
.centername .right .el-button{
  margin: 0;
}
.centername .right ::v-deep .el-button--primary {
    color: red;
    background-color: white;
    border-color: red;
    height: 35px;
    line-height: 5px;
    margin: 10px;
    width: 70px;
  
}
.centername .right ::v-deep .el-button--danger {
    color: #FFF;
    background-color: #F56C6C;
    border-color: #F56C6C;
     height: 35px;
    line-height: 5px;
    width: 125px;
  
}
.out {
  // border: 1px solid red;
  margin-top: 10px;
  .el-button {
    width: 500px;
    height: 40px;
    background: #e94f55;
    border-radius: 5px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-left: 13%;
  }
}
  .centerallimg{
    display: flex;
    justify-content:center;
    align-items: center;
  }
.centeruncode{
  width: 500px;
  // border: 1px solid red;
  margin-left: 40px;
  .centerall{
    // border: 1px solid red;
    margin-top: 60px;
    .right ::v-deep .el-button--primary {
    color: red;
    background-color: white;
    border-color: red;
    height: 35px;
    line-height: 5px;
    // margin: 10px;
    width: 70px;
  
}
.bottom{
  width: 100%;
  // border: 1px solid red;
}
.bottom ::v-deep .el-button{
    // color: red;
    // background-color: white;
    // border-color: red;
    height: 35px;
    line-height: 5px;
    // margin: 10px;
    width: 140px;
    display: inline-block;
    margin: 50px 180px;

  
}
    height: 200px;
       .aiqian{
        font-size: 14px !important;
        span{
          color: red;
          margin: 0 5px;
        }
      }
    .phone,.code{
      text-align: right;
     margin-top: 20px;
     width: 100%;
      //  border: 1px solid red;
       border-bottom: 1px solid #ccc;
       .grid-content{
         ::v-deep .el-input .el-input__inner {
            border: 0 !important;
            background-color: rgb(253,253,253);
            font-size: 18px;
         }
        ::v-deep .el-button {
          width: 100px !important;
          font-size: 16px;
          span{
            text-align: center;
            margin-left: -10px;
          }
        }
      
       }
     
       
    }
  }
}

</style>