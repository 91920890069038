<template>
  <el-container>
  
  </el-container>
</template>
<script>

import updataApi from "../../api/updataApi";
export default {
  data() {
    return {
       httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
    };
  },
  created(){
    this.getCurrUserRole()
  },
  methods: {
     //  获取当前角色信息
    getCurrUserRole() {
      updataApi.getCurrUserRole("", (res) => {
      
        if(res.data.result.code=="companyAdmin"){
           this.$router.push("admin")
        }
       
        else if(res.data.result.code=="ordinary"){
             this.$router.push("entrepreneur")
         }
        else{
           this.$router.push("general")
        }
      });
    },

   },
};
</script>
