<template>
    <div>
        <!-- 33333333333333333333333 -->
      
          <el-form :model="ruleFormthree" status-icon :label-position="labelPosition" ref="ruleFormthree" label-width="100px" class="demo-ruleFormthree" >
                 <el-form-item label="支付账户状态"  label-width="170px">
    <el-input disabled v-model="ruleFormthree.state" autocomplete="off"></el-input>
       <el-button type="danger" v-show="signingprocess==1" @click="updataemial">重发邮件 </el-button>
  </el-form-item>
  <el-form-item label="法人身份证" label-width="170px">
    <el-input v-model="legalidcoad"  disabled autocomplete="off"></el-input>
  </el-form-item>
  <el-form-item label="负责人/法人手机号" label-width="170px">
    <el-input v-model="ruleFormthree.contactmobile" disabled></el-input>
  </el-form-item>
   <el-form-item label="填写人是否法人" label-width="170px">
      <el-input disabled v-model="ruleFormthree.proposer"></el-input>
  </el-form-item>
   <el-form-item label="法人是否实际控制人" label-width="170px">
<el-input disabled v-model="ruleFormthree.incontrol"></el-input>
  </el-form-item>
   <el-form-item label="受益人是否实际控制人" label-width="170px">
    <el-input disabled v-model="ruleFormthree.inbenefit"></el-input>
  </el-form-item>
    <el-form-item label="支付密码"  label-width="170px">
    <el-input disabled v-model="age" ></el-input>
      <el-button type="danger" v-show="signingprocess==2" @click="Updatepass">修改</el-button>
  </el-form-item>
   <el-form-item label="申请邮箱" label-width="170px">
    <el-input disabled v-model="ruleFormthree.email"></el-input>
      <el-button type="danger" v-show="signingprocess==2" @click="Updateemail" >修改</el-button>
  </el-form-item>
     <el-form-item label="企业对公账号" prop="age" label-width="170px">
     <el-button  type="primary" @click="bankinformation">查看</el-button>
     <Information :addDialog="addDialog"   :resultmessag="ruleFormthree" @del="del" ></Information>
    <Iframe :iframeDialog="iframeDialog"  ref="iframe" :resulturl="resulturl" @del="del" ></Iframe>
  </el-form-item>
</el-form>
    </div>
</template>
<script>
import { Message } from "element-ui";
import Information from "./Information.vue"
import companyApi from "../../../api/companyApi"
import tradingApi from "../../../api/tradingApi"
import administratorApi from '../../../api/administrator'
import Iframe from '../../center/iframe.vue'
export default {
    data(){
      return{
        labelPosition:"left",
        ruleFormthree:{
         
        },
        resulturl:"",
        legalidcoad:"",
        iframeDialog:false,
         age:"******************",
           addDialog: false,
         
      }
    },
     props:{
     signingprocess:Number,
        
     },
    components:{
   Information,
   Iframe
    },
    mounted(){
      this.getCurrCompanyInfo()
     this.getPaymentconfigInfo()
    },
    methods:{
      // 修改邮箱
      Updateemail(){
       
tradingApi.updateEmail("",res=>{
 
  if(res.data.code==200){
     this.$store.commit("intiframestate",'Updateemail')
      this.resulturl = res.data.result + "?Address=http://"+window.location.host+"/#/iframeBack"
      this.iframeDialog = true
  }
  else{
    Message.error(res.data.message)
  }
})
      // this.iframeDialog = true
      },
      // 修改密码
Updatepass(){
  
  // this.resulturl="https://account.baofoo.com/front/v3/#/withdraw/d52864d8b2f646ba90380103085b8fc8/BM_WITHDRAW?Address=http://"+window.location.host+"/#/iframeBack";
  // this.iframeDialog = true
tradingApi.updatePayPasswordPC("",res=>{

  if(res.data.code==200){
     this.$store.commit("intiframestate",'Updatepass')
      this.resulturl = res.data.result + "?Address=http://"+window.location.host+"/#/iframeBack"
      this.iframeDialog = true
  }
  else{
    Message.error(res.data.message)
  }
})
},
// 重发邮件
updataemial(){
  tradingApi.sendBindCardEmail("",res=>{
 
  if(res.data.code==200){
     this.$store.commit("intiframestate",'Updateemail')
      this.resulturl = res.data.result
      this.iframeDialog = true
  }
  else{
    Message.error(res.data.message)
  }
})
},
     // 获取法人姓名,身份证号
      getCurrCompanyInfo(){
   companyApi.getCurrCompanyInfo("",res=>{  
           this.legalidcoad =res.data.result.idcard
          
    })
      },
      // 获取当前用户线上支付详情
          getPaymentconfigInfo(){
              administratorApi.getPaymentconfigInfo("",res=>{
               if(res.data.code=="200"){
                   this.ruleFormthree = res.data.result
                this.ruleFormthree.proposer = res.data. result.   proposer === 1?"是":"否"
                this.ruleFormthree.incontrol = res.data.result. incontrol === 1?"是":"否"
                  this.ruleFormthree.inbenefit = res.data.result.inbenefit === 1?"是":"否"
                  if(this.ruleFormthree.state==0){
                     this.ruleFormthree.state="已申请"
                  }
                  else if(this.ruleFormthree.state==1){
                   this.ruleFormthree.state="待激活，请在邮箱中进行小额鉴权"
                   setTimeout(() => {
                     this.getPaymentconfigInfo();
                   }, 20000);
                  }
                  else{
                    this.ruleFormthree.state="已开通"
                  }
               }
             
               
              })
          },

            // 查看
      bankinformation(){
      this.addDialog = true;
      },
         del(bool) {
           this.resulturl="";
      this.addDialog = bool;
      this.iframeDialog = bool
    },
    }

}
</script>
<style lang="scss" scoped>
   ::v-deep .el-input__inner{
     border: 0 !important;
     outline: 0;
     background: rgb(253,253,253);
   }
   ::v-deep .el-input.is-disabled .el-input__inner{
      color: #606266 !important;
   }
</style>