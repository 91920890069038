<template >
    <div v-if="proposer!=null" v-loading="loading" >
        <!-- 12222222222222222222 -->
            <div class="signing"  >
        <div class="sigincoad" >
           <div>
           <span>法人身份证头像图片</span>
       <el-upload
  class="avatar-uploader"
     :action="baseApi+ '/center/file/upload'"
  :show-file-list="false"
   :headers="httpheader"
  :on-success="fronthandleAvatarSuccess"
    :on-progress = "oprogress"
     :before-upload="beforeAvatarUpload"
 >
  <img v-if="phote.lpAvatar" :src="phote.lpAvatar"  class="avatar">
  <img v-else  class="avatar"
            height="150"
            width="150" src="../../../assets/home/pro.png" >
</el-upload>
  </div>

<div>
    <span>法人身份证国徽图片</span>
  <el-upload
  class="avatar-uploader"
   :action="baseApi+ '/center/file/upload'"
  :show-file-list="false"
  :headers="httpheader"
  :on-success="nexthandleAvatarSuccess"
    :on-progress = "oprogress"
     :before-upload="beforeAvatarUpload"
 >
  <img v-if="phote.lpBadge" :src="phote.lpBadge" class="avatar">
  <img v-else  class="avatar"
            height="150"
            width="150" src="../../../assets/home/before.png" >
</el-upload>
</div>
        </div>


<div v-if="proposer==0" class="sigincoad">
    <div >
           <span>填写人身份证头像图片</span>
       <el-upload
  class="avatar-uploader"
    :action="baseApi+ '/center/file/upload'"
  :show-file-list="false"
  :headers="httpheader"
  :on-success="nofronthandleAvatarSuccess"
   :before-upload="beforeAvatarUpload"
    :on-progress = "oprogress"
 >
  
    <img v-if="phote.agAvatar" :src="phote.agAvatar" class="avatar">
  <img v-else  class="avatar"
            height="150"
            width="150" src="../../../assets/home/pro.png" >
</el-upload>
  </div>

<div >
    <span>填写人身份证国徽图片</span>
  <el-upload
  class="avatar-uploader"
   :action="baseApi+ '/center/file/upload'"
  :show-file-list="false"
  :on-success="nonexthandleAvatarSuccess"
   :before-upload="beforeAvatarUpload"
    :on-progress = "oprogress"
    :headers="httpheader"
  >
 <img v-if="phote.agBadge" :src="phote.agBadge" class="avatar">
  <img v-else  class="avatar"
            height="150"
            width="150" src="../../../assets/home/before.png" >
</el-upload>
</div>
</div>
    



</div>
<div class="present">
  <el-button type="danger" @click="imgsubmit">提交</el-button>
</div>
    </div>
</template>
<script>
import { Message } from "element-ui";
import basecfg from "../../../utils/applocationCfg"
import administratorApi from '../../../api/administrator'
import baseApi from '../../../api/baseApi';
export default {
    data(){
      return{
         httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
         baseApi:basecfg.getBaseApi(),
        baseapi:baseApi.baseUrl,
         loading:false,
       phote:{
           //法人 身份证照片
        lpBadge:"",
        lpAvatar:"",
      //  
      // 填写人身份证照片
        agBadge:"",
        agAvatar:"",
       },
       proposer:null,
      }

    },
    props:{
    signingprocess:Number,
   
   },
   
  mounted(){
     this.getPaymentconfigInfo()
    },
    methods:{
      // 限制大小
         beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
         Message.error('上传大小不能超过 4MB!');
        }
        return isLt2M;
      
    },
        // 上传时
    oprogress(){
      this.loading = true
    },
    // 获取状态
      getPaymentconfigInfo(){
         administratorApi.getPaymentconfigInfo("",res=>{
       this.proposer = res.data.result.proposer
         })
      },
        // 提交
      imgsubmit(){
        if(this.phote.lpBadge&&this.phote.lpAvatar){
                administratorApi.setIdCardImage(this.phote,res=>{
         if(res.data.code==200){
           this.$emit("editsiging",1)
            this.$parent.online()
          //  再次获取状态
            
         }
         else{
             return  Message.error('未进行申请操作');
         }
       })
        }
        else{
           return  Message.error('请上传身份证照片');
        }
      
       
      },
  // 法人身份证照片前后 
fronthandleAvatarSuccess(res,file){

 if(file.response.code==308){
    this.loading = false
    return  Message.error('图片上传失败,请重新上传图片');
 }
 else{
    this.phote.lpAvatar = file.response.result
  this.loading = false
 }
 

},
nexthandleAvatarSuccess(res,file){
   if(file.response.code==308){
     this.loading = false
    return  Message.error('文件上传失败,请重新上传文件');
 }
  this.phote.lpBadge = file.response.result
   this.loading = false
},
// 填写人身份证照片前后 
nofronthandleAvatarSuccess(res,file){
   if(file.response.code==308){
     this.loading = false
    return  Message.error('文件上传失败,请重新上传文件');
 }
 this.phote.agAvatar = file.response.result
  this.loading = false
},
nonexthandleAvatarSuccess(res,file){
   if(file.response.code==308){
     this.loading = false
    return  Message.error('文件上传失败,请重新上传文件');
 }
 this.phote.agBadge =file.response.result
  this.loading = false
},
    }
}
</script>
<style lang="scss" scoped>
.signing{
 
  div{
    span{
      font-size: 14px;
      display: inline-block;
      margin-bottom: 10px;
    }
    img{
        width: 160px;
    // border: 1px solid red;
    height: 160px;
    }
  }

}
  .present{
    width: 100%;
    // border: 1px solid rgb(55, 0, 255);
    .el-button{
      width: 20%;
      margin: 40px 40%;
    }
  }
  .sigincoad{
    display: flex;
  justify-content: space-around;
  margin-top: 10px;
  }
</style>