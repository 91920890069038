<template>
  <div class="adddialog">
    <el-dialog
      class="dialog"
     
      :visible="addDialog"
      width="30%"
      :before-close="del"
      :close-on-click-modal="false"
    >
      <div class="addconfirm">
          <el-form :model="resultmessag" status-icon  :label-position="labelPosition" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
               <el-form-item label="开户名称" prop="age" label-width="130px">
   <p>{{resultmessag.acname}}</p>
  </el-form-item>
   <el-form-item label="开户银行" prop="age" label-width="130px">
  <p>{{resultmessag.acbank}}</p>
  </el-form-item>
     <el-form-item label="开户银行所在地" prop="age" label-width="130px">
   <p>{{resultmessag.provincename}}/{{resultmessag.cityname}}</p>
  </el-form-item>
     <el-form-item label="开户银行支行名称" prop="age" label-width="130px">
    <p>{{resultmessag.branchname}}</p>
  </el-form-item>
   </el-form>
      </div>
       
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
        labelPosition:'left',
    };
  },
  props: {
    addDialog: {
      type: Boolean,
      default: false,
    },
    resultmessag: {},
  },
  methods: {
    del() {
      this.$emit("del", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.adddialog {
  .el-dialog {
    min-width: 460px;
  }
  .dialog {
    text-align: center;
    
    .addconfirm {
      width: 80%;
      
      margin: 5px auto;
      text-align: left;
      height: 250px;
    }
  }
  .addconfirm p {
    margin-top: -5px;
    color: rgb(172, 169, 169);
  }
  .addchecked {
    width: 70%;
    margin: 5px auto;
    text-align: left;
    height: 50px;
    color: #333333;
    font-size: 14px;
    .el-checkbox__inner {
      border-radius: 50%;
    }
  }
  .addbutton .el-button {
    width: 150px;
    margin: auto;
  }
}

</style>