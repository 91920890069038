<template>
  <div>
    <!-- 11111111111111111111111 -->
    <el-form
      :model="ruleFormone"
      status-icon
      :rules="rules"
      ref="ruleFormname"
      :label-position="labelPosition"
      label-width="100px"
      class="demo-ruleFormone"
    >
      <el-form-item label="法人姓名" label-width="200px">
        <el-input v-model="legalname" disabled autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="法人身份证" label-width="200px">
        <el-input disabled v-model="legalidcoad" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="法人手机号"
        prop="contactmobile"
        label-width="200px"
      >
        <el-input v-model="ruleFormone.contactmobile"></el-input>
      </el-form-item>
      <el-form-item label="填写人是否法人" prop="proposer" label-width="200px">
        <el-radio-group v-model="ruleFormone.proposer" @change="changefaren">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="法人是否是实际控制人"
        prop="incontrol"
        label-width="200px"
      >
        <el-radio-group v-model="ruleFormone.incontrol" @change="changekongzhi">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <div v-if="ruleFormone.incontrol == 0">
        <el-form-item
          label="实际控制人类型"
          prop="controllertype"
          label-width="200px"
        >
          <el-radio-group v-model="ruleFormone.controllertype">
            <el-radio label="0">个人</el-radio>
            <el-radio label="1">企业</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="ruleFormone.controllertype == 0">
          <el-form-item
            label="实际控制人归属地"
            prop="controllerarea"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.controllerarea"></el-input>
          </el-form-item>
          <el-form-item
            label="实际控制人姓名"
            prop="controllername"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.controllername"></el-input>
          </el-form-item>
          <el-form-item
            label="实际控制人身份证号"
            prop="controlleridcard"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.controlleridcard"></el-input>
          </el-form-item>
          <el-form-item
            label="实际控制人身份证有效期限"
            prop="controlleridcardexpiredate"
            label-width="200px"
          >
            <el-date-picker
              v-model="ruleFormone.controlleridcardexpiredate"
              type="date"
              placeholder="选择日期"
              size="large"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="实际控制人身份证是否长期"
            prop="controlleridcardextended"
            label-width="200px"
          >
            <el-radio-group v-model="ruleFormone.controlleridcardextended">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div v-if="ruleFormone.controllertype == 1">
          <el-form-item
            label="实际控制人企业名称"
            prop="controllercompanyname"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.controllercompanyname"></el-input>
          </el-form-item>
          <el-form-item
            label="实际控制人社会信用代码"
            prop="controllerregno"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.controllerregno"></el-input>
          </el-form-item>
          <el-form-item
            label="实际控制人营业期限是否长期"
            prop="controllerbusinessextended"
            label-width="200px"
          >
            <el-input
              v-model="ruleFormone.controllerbusinessextended"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="实际控制人营业有效期限"
            prop="controllerbusinessexpiredate"
            label-width="200px"
          >
            <el-date-picker
              v-model="ruleFormone.controllerbusinessexpiredate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </div>
      <el-form-item
        label="受益人是否实际控制人"
        prop="inbenefit"
        label-width="200px"
      >
        <el-radio-group v-model="ruleFormone.inbenefit" @change="changeshouyi">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="ruleFormone.inbenefit == 0">
        <el-form-item
          label="受益人类型"
          prop="beneficiarytype"
          label-width="200px"
        >
          <el-radio-group v-model="ruleFormone.beneficiarytype">
            <el-radio label="0">个人</el-radio>
            <el-radio label="1">企业</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 个人 -->
        <div v-if="ruleFormone.beneficiarytype == 0">
          <el-form-item
            label="受益人归属地"
            prop="beneficiaryarea"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.beneficiaryarea"></el-input>
          </el-form-item>
          <el-form-item
            label="受益人姓名"
            prop="beneficiaryname"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.beneficiaryname"></el-input>
          </el-form-item>
          <el-form-item
            label="受益人身份证号"
            prop="beneficiaryidcard"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.beneficiaryidcard"></el-input>
          </el-form-item>
          <el-form-item
            label="收益人身份证是否长期"
            prop="beneficiaryidcardextended"
            label-width="200px"
          >
            <el-radio-group v-model="ruleFormone.beneficiaryidcardextended">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="受益人身份证有效期限"
            prop="beneficiaryidcardexpiredate"
            label-width="200px"
          >
            <el-date-picker
              v-model="ruleFormone.beneficiaryidcardexpiredate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <!-- 企业 -->
        <div v-if="ruleFormone.beneficiarytype == 1">
          <el-form-item
            label="受益人企业名称"
            prop="beneficiarycompanyname"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.beneficiarycompanyname"></el-input>
          </el-form-item>
          <el-form-item
            label="受益人社会信用代码"
            prop="beneficiaryregno"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.beneficiaryregno"></el-input>
          </el-form-item>
          <el-form-item
            label="受益人企业名称"
            prop="beneficiarycompanyname"
            label-width="200px"
          >
            <el-input v-model="ruleFormone.beneficiarycompanyname"></el-input>
          </el-form-item>
          <el-form-item
            label="受益人营业期限是否长期"
            prop="beneficiarybusinessextended"
            label-width="200px"
          >
            <el-radio-group v-model="ruleFormone.beneficiarybusinessextended">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="受益人营业有效期限"
            prop="beneficiarybusinessexpiredate"
            label-width="200px"
          >
            <el-date-picker
              v-model="ruleFormone.beneficiarybusinessexpiredate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="联系邮箱" prop="email" label-width="200px">
        <el-input v-model="ruleFormone.email"></el-input>
      </el-form-item>
      <el-form-item label="开户名称" prop="acname" label-width="200px">
        <el-input v-model="ruleFormone.acname"></el-input>
      </el-form-item>
      <el-form-item label="开户银行" prop="acbank" label-width="200px">
        <el-input v-model="ruleFormone.acbank"></el-input>
      </el-form-item>
      <el-form-item label="开户银行所在地" label-width="200px" class="provin">
        <el-cascader :options="options" @change="coaddressChange"></el-cascader>
      </el-form-item>

      <el-form-item
        label="开户银行支行名称"
        prop="branchname"
        label-width="200px"
      >
        <el-input v-model="ruleFormone.branchname"></el-input>
      </el-form-item>
      <el-form-item
        label="企业对公账号"
        prop="publicaccount"
        label-width="200px"
      >
        <el-input v-model="ruleFormone.publicaccount"></el-input>
      </el-form-item>
      <el-form-item label="支付协议" prop="xieyi" label-width="200px">
        <el-checkbox v-model="checked" :checked="checked" @click="sure"
          >已同意并仔细阅读 </el-checkbox
        ><a class="xiansahng" @click="xianshang">线上支付协议</a>
      </el-form-item>
      <Goodsure
        :addDialog="addDialog"
        @del="del"
        ref="child"
        @addsure="addsure"
      ></Goodsure>
      <el-form-item>
        <el-button
          type="danger"
          class="nextdanger"
          @click="nextprocess(ruleFormone)"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import administratorApi from "../../../api/administrator";
import { Message } from "element-ui";
import companyApi from "../../../api/companyApi";
import Goodsure from "../entre/goodsurelog.vue";
import cityCode from "../../../unitls/city_code";
export default {
  data() {
    return {
      legalidcoad: "",
      legalname: "",
      label: "",
      options: [],
      addDialog: false,
      checked: false,
      ruleFormone: {
        proposer: 1,
        incontrol: 1,
        inbenefit: 1,
      },
      labelPosition: "left",
      rules: {
        acbank: [{ required: true, message: "请输入开户银行" }],
        contactmobile: [{ required: true, message: "请输入联系人手机号" }],
        acname: [{ required: true, message: "请输入开户银行名称" }],
        branchname: [{ required: true, message: "请输入开户银行支行名称" }],
        email: [{ required: true, message: "请输入邮箱" }],
        inbenefit: [{ required: true, message: "请选择..." }],
        incontrol: [{ required: true, message: "请选择..." }],
        proposer: [{ required: true, message: "请选择..." }],

        cityname: [{ required: true, message: "请选择开户银行所在市" }],
        publicaccount: [{ required: true, message: "请选择" }],
      },
    };
  },
  props: {
    signingprocess: Number,
  },
  mounted() {
    // 城
    this.options = cityCode;
    this.getCurrCompanyInfo();
  },
  components: {
    Goodsure,
  },
  methods: {
    del(bool) {
      this.addDialog = bool;
    },
    addsure(bool) {
      this.addDialog = bool;
    },
    //    // 获取法人姓名,身份证号
    getCurrCompanyInfo() {
      companyApi.getCurrCompanyInfo("", (res) => {
        this.legalidcoad = res.data.result.idcard;
        this.legalname = res.data.result.lpname;
      });
    },
    //  下一步
    nextprocess(ruleFormone) {
      if (this.checked == false) {
        Message.error("点击阅读,才能登录");
        return;
      }

      if (ruleFormone.proposer) {
        ruleFormone.proposer = parseInt(ruleFormone.proposer);
      }
      if (ruleFormone.inbenefit) {
        ruleFormone.inbenefit = parseInt(ruleFormone.inbenefit);
      }
      if (ruleFormone.incontrol) {
        ruleFormone.incontrol = parseInt(ruleFormone.incontrol);
      }
      if (ruleFormone.beneficiarybusinessextended) {
        ruleFormone.beneficiarybusinessextended = parseInt(
          ruleFormone.beneficiarybusinessextended
        );
      }
      if (ruleFormone.beneficiaryidcardextended) {
        ruleFormone.beneficiaryidcardextended = parseInt(
          ruleFormone.beneficiaryidcardextended
        );
      }
      if (ruleFormone.controllerbusinessextended) {
        ruleFormone.controllerbusinessextended = parseInt(
          ruleFormone.controllerbusinessextended
        );
      }
      if (ruleFormone.controlleridcardextended) {
        ruleFormone.controlleridcardextended = parseInt(
          ruleFormone.controlleridcardextended
        );
      }
      if (ruleFormone.controllertype) {
        ruleFormone.controllertype = parseInt(ruleFormone.controllertype);
      }
      if (ruleFormone.beneficiarytype) {
        ruleFormone.beneficiarytype = parseInt(ruleFormone.beneficiarytype);
      }
      this.$refs.ruleFormname.validate((valid) => {
        if (valid) {
          administratorApi.addPanymengconfig(ruleFormone, (res) => {
        
            if (res.data.code == 200) {
              this.$emit("editsiging", 0);
              // this.ruleFormone = {};
            } else {
              Message.error(res.data.message);
            }
          });
        }
      });
    },

    pgetTextByValue(e) {
      var label = "";
      var s = this.options.filter(function (res) {
        return res.value == e[0];
      });
      if (s.length > 0) {
        label += s[0].label;
        var c = s[0].children.filter(function (res) {
          return res.value == e[1];
        });
        if (c.length > 0) {
          // label += c[0].label;
          var q = c[0].children.filter(function (res) {
            return res.value == e[2];
          });
          // label += q[0].label;
        }
      }
      return label;
    },
    cgetTextByValue(e) {
      var label = "";
      var s = this.options.filter(function (res) {
        return res.value == e[0];
      });
      if (s.length > 0) {
        // label += s[0].label;
        var c = s[0].children.filter(function (res) {
          return res.value == e[1];
        });
        if (c.length > 0) {
          label += c[0].label;
          var q = c[0].children.filter(function (res) {
            return res.value == e[2];
          });
          // label += q[0].label;
        }
      }
      return label;
    },

    // 公司地址
    coaddressChange(value) {
     

      this.ruleFormone.provincename = this.pgetTextByValue(value);
      this.ruleFormone.cityname = this.cgetTextByValue(value);
    },

    // 填写人是否法人
    changefaren(e) {
    
      this.ruleFormone.proposer = e;
      localStorage.setItem("proposer", this.ruleFormone.proposer);
    },
    // 法人是否实际控制人
    changekongzhi(e) {
     
      this.ruleFormone.incontrol = e;
    },
    // 受益人是否实际控制人
    changeshouyi(e) {
     
      this.ruleFormone.inbenefit = e;
    },
    // 线上支付协议
    xianshang() {
      this.addDialog = true;
      this.$store.commit("intagreement", "platformServices");
      this.$refs.child.getAgreement();
    },
    sure() {
      this.checked = !this.checked;
    },
  },
};
</script>
<style lang="scss" scoped>
.nextdanger {
  width: 30%;
  margin-left: 100px !important;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 350px;
}
.xiansahng {
  cursor: pointer;
  margin-left: 5px;
  color: rgb(64, 158, 255);
  border-bottom: 1px solid rgb(64, 158, 255);
}
::v-deep .provin .el-form-item__content {
  border: 1px solid #dcdfe6;
  outline: 0;
}
.el-cascader {
  width: 200px;
}
</style>